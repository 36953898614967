.about-section {
    padding: 3rem;
    background-color: #f8f9fa;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
}

.about-section h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #333;
    position: relative;
    font-weight: 700;
}

.about-section h1::after {
    content: '';
    width: 60px;
    height: 5px;
    background: linear-gradient(45deg, #3498db, #8e44ad);
    display: block;
    margin: 1rem auto 0;
    border-radius: 2px;
}

.introduce {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.profile {
    margin-bottom: 2rem;
}

.profile-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #ddd;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-img:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.my-introduce {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.info-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.info-item p {
    margin: 0;
    font-size: 1.1rem;
    color: #555;
}

.icon {
    font-size: 2rem;
    color: #3498db;
    transition: color 0.3s ease;
}

.icon:hover {
    color: #8e44ad;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
