@font-face {
    font-family: "Pretendard-Regular";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "LeferiPoint-BlackA";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/LeferiPoint-BlackA.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }
  .customer-body {
    background-color: #292c31;
  }
  .customerbox1 {
    display: flex;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 880px;
    background-color: #292c31;
    margin: 0;
  }
  .customerinquiry {
    display: flex;
  }
  .customerinquirys{
    font-size: 30px;
    color: #fff;
    margin: 20px 0 0 2px;
  }
  /* 제목 리스트 버튼 */
  .customerselectboxes{
    width: 150px;
    height: 40px;
    font-family: "Pretendard-Regular";
    font-size: 18px;
    text-indent: 15px;
    color: #000;
    border-radius: 5px;
    margin: 20px 0 20px 680px;
  }
  .customerselectbox{
    width: 140px;
    height: 40px;
    font-family: "Pretendard-Regular";
    font-size: 18px;
    text-indent: 25px;
    color: #000;
    border-radius: 5px;
    margin: 20px 0 20px 8px;
  }
  /* 검색 버튼 */
  .searchinput {
    width: 320px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-family: "Pretendard-Regular";
    text-indent: 10px;
  }
  .arrowbtn {
    background-color: transparent;
    border: none;
  }
  .customernum, .customeritem, .customerdivison, .customerwriter, .customerdate, .customeranswer {
    background-color: #4ce577;
    border-bottom: 1px solid #292c31;
    border-left: 1px solid #575757;
  }
  td {
    border-left: 1px solid #b1b1b1;
    border-bottom: 1px solid #ddd;
  }
  .lockicon {
    width: 16px;
    height: 15px;
    margin: 0 10px 0 0;
  }
  .customertab {
    justify-content: center;
    margin: 160px 0 0 0;
    padding: 0;
  }
  .customertable {
    background-color: #fff;
    font-size: 18px;
    color: #000;
    border: 2.5px solid #1c1c1c;
    border-color: #000;
    border-collapse: collapse;
  }
  .customernum {
    width: 80px;
  }
  .customeritem{
    width: 100px;
  }
  .customerdivison {
    width: 680px;
  }
  .customerwriter {
    width: 120px;
  }
  .customerdate {
    width: 220px;
  }
  .customeranswer {
    width: 110px;
  }
  /* 문의 제목 */
  .customernums {
    text-align: center;
    height: 40px;
    font-size: 18px;
  }
  .customerdivisons {
    text-indent: 15px;
    font-size: 18px;
    text-align: left;
  }
  .customerwriters {
    text-align: center;
    font-size: 18px;
  }
  .customerdates {
    text-align: center;
    font-size: 18px;
  }
  .customeranswers {
    text-align: center;
    font-size: 18px;
  }
  .customerbox2 {
    position: absolute;
    z-index: 2;
    background-color: #d4fe76;
    width: 100%;
    height: 150px;
    margin: 0 0 0 0;
  }
  .customerservice {
    text-align: center;
    font-family: "LeferiPoint-BlackA";
    font-size: 50px;
    color: #1c1c1c;
    margin: 50px 0 0 0;
  }
  /* 쪽수 버튼 */
  .customerarrow {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 0 0 0;
  }
  .searchinput{
    margin: 20px 0 20px 10px;
  }
  .searchbtn {
    width: 80px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    margin: 20px 0 20px 10px;
  }
  .arrowlefticon {
    width: 35px;
    height: 35px;
  }
  .arrowrighticon {
    width: 35px;
    height: 35px;
  }
  .pagebtn {
    background-color: transparent;
    border: none;
  }
  .pagenums{
    background-color: transparent;
    border: none;
    font-size: 25px;
    color: #fff;
    margin: 0 20px 0 20px;
  }
  /* 작성하기 버튼 */
  .customerwrite{
    display: flex;
    justify-content: end;
    text-align: end;
  }
  #bt.customerwrites{
    width: 98px;
    height: 40px;
    background-color: #D4FE76;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Pretendard-Regular";
    border-radius: 5px;
  }
  
  .paginations{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .paginations{
    padding: 8px 12px;
    border-radius: 4px;
    outline: none; /* 포커스 테두리 제거 */
    color: #000;
    margin: 5px;
    align-items: center;
  }
  
  .ss button{
    margin-left: 8px;
    color: #000;
    background-color: #d6d6d6;
    font-size: 18px;
  }