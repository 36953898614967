.projects-section {
    margin: 2rem auto;
    padding: 2rem;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: margin-top 0.5s ease-in-out;
    text-align: center;
}

.projects-section h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #333;
}

.projects-section h2 {
    text-align: center;
    margin: 0 0 1rem;
    font-size: 2rem;
    color: #333;
}

.project-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.project-item {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    width: 280px;
    text-align: left;
    cursor: pointer;
}

.project-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.project-item img {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.project-item h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.project-item p {
    margin: 0.5rem 0 0;
    color: #666;
}

@media (max-width: 768px) {
    .projects-section {
        margin: 1rem auto;
        padding: 1.5rem;
    }
    .projects-section h1 {
        font-size: 2.5rem;
    }
    .projects-section h2 {
        font-size: 1.75rem;
    }
    .project-item {
        width: 100%;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .projects-section {
        margin: 0.5rem auto;
        padding: 1rem;
    }
    .projects-section h1 {
        font-size: 2rem;
    }
    .projects-section h2 {
        font-size: 1.5rem;
    }
}
