.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-content {
  background: #fff;
  width: 50%;
  max-width: 650px;
  max-height: 600px;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  animation: slide-in 0.3s ease-out;
  font-size: 0.9rem; /* 전체 글씨 크기 조정 */
}

@keyframes slide-in {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-title {
  font-size: 1.4rem; /* 글씨 크기 조정 */
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 0.5rem;
}

.user-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.user-info img {
  width: 30px; /* 이미지 크기 조정 */
  height: 30px; /* 이미지 크기 조정 */
  border-radius: 50%;
  margin-right: 1rem;
}

.user-info .user-email {
  font-weight: bold;
  color: #4caf50;
}

.user-info .question-date {
  display: block;
  font-size: 0.8rem;
  color: #888;
}

.question-details {
  background-color: #f9f8f8;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.question-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.question-content {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 17px;
}

.answer-section {
  margin-top: 1rem;
  background-color: #e8f5e9;
  padding: 1rem;
  border-radius: 10px;
}

.answer-title {
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.answer-content {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 17px;
  margin-top: 1rem; /* 답변들 간의 간격 조정 */
}

.modal-body {
  padding: 1rem 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.button {
  padding: 0.6rem 1.2rem;
  margin-left: 20px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease; 
  font-size: 0.8rem; /* 글씨 크기 조정 */
}

.close-button {
  background-color: #d32f2f;
}

.close-button:hover {
  background-color: #c62828;
}

.save-button {
  background-color: #4caf50;
}

.save-button:hover {
  background-color: #45a049;
}

textarea.styled-textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #333;
  resize: vertical; /* 세로로만 크기 조정 가능 */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

textarea.styled-textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  outline: none;
}
