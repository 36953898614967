@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
.modalWrite {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modalWrite-content {
  background: #fff;
  width: 700px;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.modalWrite-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modalWrite-header {
  margin-bottom: 1.5rem;
}

.modalWrite-title {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}
.modalWrite-titles {
  margin: 0;
}
.modalCheckbox-container {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  margin: 0 0 0 10px;
}
.checkBoxes {
  display: flex;
}
.checkBox {
  width: 15px;
  background-color: #45a049;
}
.checkboxOpen {
  font-size: 18px;
  font-weight: 200;
  margin-right: 10px;
  margin-top: 16px;
  margin-left: 5px;
}
.checkboxPrivate {
  font-size: 18px;
  font-weight: 200;
  margin-top: 16px;
  margin-left: 5px;
}
.ModalWriteselectbox {
  width: 640px;
  align-items: center;
  font-size: 18px;
  font-family: "Pretendard-Regular";
  background-color: #f9f8f8;
  padding: 15px;
}
.ModalWrite-opt {
  width: 100%;
}
#ModalWriteTitles{
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 5px;
  color: #45a049;
}
#ModalWriteTitle {
  width: 570px;
  height: 35px;
  font-size: 20px;
  font-family: "Pretendard-Regular";
  text-indent: 7px;
  margin: 15px 0 0 10px;
  border: none;
}
.ModalWriteuserInrtos {
  display: flex;
  align-items: center;
}
.modalWrite-body {
  padding: 0 0 10px 0;
}

.modalWrite-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.modalWriteClosebutton {
  padding: 0.8rem 1.6rem;
  margin-left: 20px;
  font-weight: 600;
  background-color: #d32f2f;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalWriteClosebutton:hover {
  background-color: #d32f2f;
}
.modalWriteSavebutton {
  padding: 0.8rem 1.6rem;
  margin-left: 20px;
  font-weight: 600;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalWriteSavebutton:hover {
  background-color: #45a049;
}

textarea.styled-textarea {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  text-indent: 7px;
  font-family: "Pretendard-Regular";
  color: #333;
  resize: vertical; /* 세로로만 크기 조정 가능 */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #f7f7f7;
}
textarea.styled-textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  outline: none;
}

.titleIntro p {
  font-weight: 900;
}
