.skill-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex: 1; /* 부모의 flexbox 속성을 계승하여 유연하게 높이 설정 */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skills-chart {
    width: 100%;
    height: 100%; /* 부모의 높이를 차지하도록 설정 */
    display: flex;
    align-items: center; /* 내부 캔버스를 중앙에 정렬 */
}

.skills-chart canvas {
    width: 100%;
    height: 100%; /* 부모의 크기를 차지하도록 설정 */
}
