.start {
  background-color: #e4fab5; /* 연두색 배경 */
  position: relative;
  width: 1800px;
}

.startIn {
  background-color: white; /* 흰색 배경 */
  margin: 20px; /* 모든 측면에 15px 마진 */
  width: 97%;
  height: 96%;
  border: 3px solid black;
  border-radius: 10px;
}

.startTitle {
  padding: 20px;
  margin: 0;
}

hr {
  margin: 0;
}

.summaryDetails {
  display: flex;
  margin: 12px;
}

.detailBorder {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 12px;
}
.newTitle {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.newCount {
  flex-grow: 1;
  width: 100%;
}

.addC {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.addCount {
  width: 100%;
}

.answerC {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.answerCount {
  width: 100%;
}

.buttonss {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center; /* 중앙 정렬 */
  background-color: #f5f5f5; /* 배경색 추가 */
  border-radius: 8px; /* 모서리 둥글게 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
}

.seletes {
  display: flex;
  gap: 10px; /* 요소들 사이의 간격 추가 */
}

.select,
.delete,
input[type="text"],
button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; /* 포커스 테두리 제거 */
  font-size: 16px;
  font-weight: 600;
  font-family: "Pretendard-Regular";
}

input[type="text"] {
  flex-grow: 1; /* 입력 필드 너비 자동 조정 */
}

button {
  cursor: pointer; /* 마우스 오버시 커서 변경 */
  background-color: #4caf50; /* 버튼 색상 변경 */
  color: white; /* 텍스트 색상 변경 */
  border: none; /* 테두리 제거 */
}

.delete:hover {
  background-color: #45a049; /* 마우스 오버시 버튼 색상 변경 */
}

.table-responsive {
  margin-top: 20px;
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  font-size: 20px;
}

th,
td {
  text-align: center;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #e4fab5; /* 테이블 헤더에 연두색 배경 적용 */
  color: #333; /* 헤더 텍스트 색상 */
}

tr:hover {
  background-color: #f5f5f5;
}
.Inquiryselect{
  width: 80px;
}
.Inquirynum{
  width: 80px;
}
.Inquirytype{
  width: 110px;
}
.Inquirytitle{
  width: 500px;
}
.Inquiryid{
  width: 300px;
}
.Inquirydate{
  width: 300px;
}
.Inquiryanswer{
  width: 110px;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}

input[type="checkbox"] {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.red-text {
  color: red; /* 빨간색 글씨 */
  font-weight: 600;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; /* 포커스 테두리 제거 */
  background-color: white;
  color: #000;
  margin: 5px;
}
