.header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed; /* 상단에 고정 */
    top: 0;
    width: 100%;
    z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 설정 */
}

.header nav a {
    color: #fff;
    margin: 0 1rem;
    text-decoration: none;
}

.header nav a:hover {
    text-decoration: underline;
}
