@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Pretendard-Regular";
}

.home a {
  width: 295px;
  background-color: #494a4a;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 81px;
  font-size: 18px;
}

.homeIcon {
  margin-left: 25px;
  font-size: 20px;
}

.home p {
  font-size: 20px;
  margin-left: 15px;
}

.adminIntroduce {
  width: 295px;
  height: 795px;
  background-color: #292c31;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 280px;
}

.adminIntroduce {
  color: white;
  font-size: 25px;
}
.adminsimbol{
  width: 100px;
  height: 85px;
  margin: 10px 0 0 0;
}
.admin1 {
  margin: 25px 0 50px 0;
}
/* 대시보드 */
.admintool{
  display: flex;
  width: 295px;
  height: 80px;
  background-color: #292c31;
  border: none;
  text-decoration: none;
}
.facircle {
  font-size: 11px;
  color: #fff;
  margin: 35px 0 0 50px;
}
.admintools {
  font-size: 20px;
  color: #fff;
  margin: 28px 0 0 15px;
}
/* 문의사항 */
.admincustomer{
  display: flex;
  width: 295px;
  height: 80px;
  background-color: #292c31;
  margin-top: 0;
  border: none;
  text-decoration: none;
}
.facirclewhite {
  font-size: 11px;
  color: #fff;
  margin: 35px 0 0 50px;
}
.menuListcustomers{
  font-size: 20px;
  color: #fff;
  margin: 28px 0 0 15px;
}
/* 회원관리 */
.adminuser{
  display: flex;
  width: 295px;
  height: 80px;
  background-color: #292c31;
  margin-top: 0;
  border: none;
  text-decoration: none;
}
.facirclewhite {
  font-size: 11px;
  color: #fff;
  margin: 35px 0 0 50px;
}
.adminuseres{
  font-size: 20px;
  color: #fff;
  margin: 28px 0 0 15px;
}
/* 기능 업데이트 */
.adminupdate{
  display: flex;
  width: 295px;
  height: 80px;
  background-color: #292c31;
  margin-top: 0;
  border: none;
  text-decoration: none;
}
.facirclewhite {
  font-size: 11px;
  color: #fff;
  margin: 35px 0 0 50px;
}
.adminupdates{
  font-size: 20px;
  color: #fff;
  margin: 28px 0 0 15px;
}

.logout {
  width: 295px;
  background-color: #7a7a7a;
  margin: 0;
  color: white;
  display: flex;
  font-size: 18px;
  height: 87px;
  align-items: center;
}

.logout p {
  margin: 0;
  margin-left: 10px;
}

.logoutIcon {
  margin-left: 20px;
}


/* Option hover 효과를 위한 스타일 */
.menuList:hover {
  cursor: pointer;
}

/* 로그아웃 */
.adminlogout{
  display: flex;
  width: 295px;
  height: 80px;
  background-color: #494a4a;
  margin: 0 0 0 0;
}
.faRightFromBracket{
  font-size: 25px;
  color: #d1d1d1;
  margin: 25px 0 0 25px;
}
.adminlogouts{
  font-size: 20px;
  color: #fff;
  margin: 25px 0 0 25px;
  text-decoration: none;
}